<template>
    <b-card title="Home">
        <b-form>
            <b-row>
                <b-col md="2"></b-col>
                <b-col md="8">
                    <b-form-radio v-on:click="isHidden = !isHidden" v-model="Selected" name="search"
                        value="Search Customer" class="mt-2 mr-2">
                        Search Customer (Name, Address, Mobile Number, Membership id or customer id)
                    </b-form-radio>
                    <b-form-radio v-on:click="isHidden = !isHidden" v-model="Selected" name="search"
                        value="Search By Invoice" class="mt-1 mr-1">
                        Search Invoice
                    </b-form-radio>
                    <b-form>
                        <b-input-group class="mt-2 mr-2">
                            <b-input-group-prepend>
                                <div v-if="Selected == 'Search Customer'">
                                    <vSelect :options="searchCritriaOption" label="name" v-model="searchCritria"
                                        placeholder="Search ">
                                    </vSelect>
                                </div>
                            </b-input-group-prepend>
                            <b-col>
                                <v-select v-if="Selected == 'Search Customer'" class="w-100" :filterable="false"
                                    :placeholder="Selected" :options="customersList" @search="onInputChange"
                                    v-model="searchBoxValue" @input="onSelected">
                                    <template #option="{ fullName, mobileNo, id }">
                                        <h4>{{ fullName }}{{ [id] }}</h4>
                                        <span>{{ mobileNo }} </span>
                                    </template>
                                </v-select>
                            </b-col>
                            <v-select v-if="Selected == 'Search By Invoice'" class="w-100" :filterable="false"
                                v-model="searchBoxForOrderNo" :placeholder="Selected" :options="customersOrderNo"
                                @search="onInputChangeOnInvoice" @input="onSelectedOrderNo">
                                <template #option="{ orderId, orderNo, customerName, customerId, customerMobileNo }">
                                    <h4>#{{ orderNo }}</h4>
                                    <span>{{ customerName }} {{ [customerMobileNo] }} </span>
                                </template>
                            </v-select>

                        </b-input-group>
                    </b-form>



                    <b-container class="text-center">
                        <b-button variant="primary" class="mt-1 mr-1" to="/customer/add-new">
                            <feather-icon icon="UserPlusIcon" class="mr-50" />Add New Customer
                        </b-button>
                        <b-button variant="secondary" class="mt-1 mr-1" to="/admin/daily-dashboard">
                            <feather-icon icon="TvIcon" class="mr-50" />Daily Dashboard
                        </b-button>
                    </b-container>
                </b-col>
                <b-col md="2"></b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BCol, BRow, BFormGroup, BFormInput, BCardText, BFormRadio, BButton, BContainer, BInputGroup, BInputGroupPrepend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import api from '@/store/api'
import { VueAutosuggest } from 'vue-autosuggest'
import util from '@/store/utils'


export default {
    components: {
        BCard, BForm, BCol, BRow, BFormGroup, BCardText,
        BFormInput, BFormRadio, BContainer, BInputGroup, BButton, BInputGroupPrepend,
        vSelect, VueAutosuggest
    },
    data() {
        return {
            searchCritriaOption: [],
            searchCritria: { 'filterType': 'mobileNo', 'name': 'MOBILE_NO' },
            Selected: 'Search Customer',
            searchBoxValue: '',

            customerName: '---Please Enter Customer Name, Mobile No etc. ----',
            customerId: 0,
            customersList: [],
            selected1: { name: 'Vikas', mobile: '9999999999', address: 'Jaipur' },
            isHidden: false,
            customersOrderNo: [],
            searchBoxForOrderNo: '',

        }
    },
    methods: {
        getCustomerList(text) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/customers/filter/' + self.searchCritria.filterType + ',' + text, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersList = response.data;
                    } else {
                        self.customersList = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        onSelected(option) {
            this.selected = option.item
            this.$router.push({ name: 'customerDetail', params: { id: option.id } })

        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            this.getCustomerList(text);
        },
        onSelectedOrderNo(option1) {
            this.selected = option1.item
            this.$router.push({ name: 'orderDetail', params: { orderId: option1.orderId } })
        },

        onInputChangeOnInvoice(orderNo) {
            if (orderNo === '' || orderNo === undefined) {
                return
            }
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersFilteredListByOrderNoContaining/' + orderNo, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersOrderNo = response.data;
                    } else {
                        self.customersOrderNo = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getSearchFiltersList() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getSearchFiltersList', data))
                .then(function (response) {
                    for (let index = 0; index < response.data.length; index++) {
                        if (response.data[index] == 'ALL') {
                            self.searchCritriaOption.push({ 'filterType': 'all', 'name': response.data[index] })
                        } else if (response.data[index] == 'NAME') {
                            self.searchCritriaOption.push({ 'filterType': 'name', 'name': response.data[index] })
                        } else if (response.data[index] == 'ADDRESS') {
                            self.searchCritriaOption.push({ 'filterType': 'address', 'name': response.data[index] })
                        } else if (response.data[index] == 'MOBILE_NO') {
                            self.searchCritriaOption.push({ 'filterType': 'mobileNo', 'name': response.data[index] })
                        } else if (response.data[index] == 'EMAIL_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'emailId', 'name': response.data[index] })
                        } else if (response.data[index] == 'MEMBERSHIP_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'membershipId', 'name': response.data[index] })
                        }

                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
    },
    created() {
        this.getSearchFiltersList();
    }

}
</script>

